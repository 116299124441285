$.fn.isInViewport = function () {
  if ($(this).length) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  } else {
    return false;
  }
};

$(function () {
  // Navbar dropdown
  $(".navbar-collapse").on("show.bs.collapse", function () {
    $("body").append('<div class="navbar-backdrop"></div>');
    $("body").addClass("has-backdrop");
  });
  $(".navbar-collapse").on("hide.bs.collapse", function () {
    $("body").removeClass("has-backdrop");
    $(".navbar-backdrop").remove();
  });
  $(window).resize(function () {
    if ($(window).outerWidth() > 800) {
      $("body").removeClass("has-backdrop");
      $(".navbar-backdrop").remove();
    }
  });
  
  // Navbar connected dropdown
  $(document).on("click", function(e) {
    if ($(window).outerWidth() > 760) {
      if (
            ($(e.target).closest('.is-logged-in').length && !$(e.target.parentElement).hasClass('dropdown-open'))
        ||  (!$(e.target).closest('.login-dropdown').length && $('.login.is-logged-in').hasClass('dropdown-open') && !$(e.target.parentElement).is('.login', '.is-logged-in'))
        ) {
        e.preventDefault();
        $('.login-dropdown').toggleClass('is-open');
        $('.login.is-logged-in').toggleClass('dropdown-open');

      }
    }
  })

  // intl tel input
  $(".use-intl").each(function () {
    var input = $(this)[0];
    window.intlTelInput(input, {
      initialCountry: "auto",
      geoIpLookup: function (success, failure) {
        $.get("/geoip-lookup").always(function (resp) {
          var countryCode = resp && resp.country ? resp.country : "fr";
          success(countryCode);
        });
      },
      placeholderNumberType: "MOBILE",
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.1/js/utils.js",
    });
  });

  $(".phone-form").each(function () {
    const parentForm = $(this);
    const input = parentForm.find(".use-intl");
    const dialCode = parentForm.find('input[name="dialCode"]');
    const iti = window.intlTelInputGlobals.getInstance(input[0]);

    input.on("countrychange", function () {
      dialCode.val(iti.getSelectedCountryData().dialCode);
    });

    input.trigger('countrychange')
  });

  $(".use-intl").on("input", (event) => {
    const iti = window.intlTelInputGlobals.getInstance($(event.target)[0]);
    const curPos =
      getCaretPosition($(event.target)[0]) +
      ($(event.target).val().length === getCaretPosition($(event.target)[0]));
    if (
      iti.getSelectedCountryData().dialCode === "33" &&
      event.originalEvent &&
      event.originalEvent.inputType &&
      event.originalEvent.inputType.match(/deleteContent.+/g) === null
    ) {
      const newVal = formatPhone($(event.target).val());
      $(event.target).val(newVal);
      setCaretPosition($(event.target)[0], curPos);
    }
  });

  // dropdowns sub-menus
  $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event) {
    event.preventDefault();
    event.stopPropagation();

    $(".dropdown-submenu .show").removeClass("show");

    $(this).siblings().toggleClass("show");

    if (!$(this).next().hasClass("show")) {
      $(this)
        .parents(".dropdown-menu")
        .first()
        .find(".show")
        .removeClass("show");
    }
    $(this)
      .parents("li.nav-item.dropdown.show")
      .on("hidden.bs.dropdown", function (e) {
        $(".dropdown-submenu .show").removeClass("show");
      });
  });

  $("#mobile-track-btn .btn, #pricing-btn").on(
    "touchstart pointerdown click",
    () => {
      $(window).scrollTop(0);
      window.setTimeout(function () {
        $('#track-form .use-intl').focus();
      }, 150);
    }
  );
});

// Restricts input for each element in the set of matched elements to the given inputFilter.
// Source: https://stackoverflow.com/questions/995183/how-to-allow-only-numeric-0-9-in-html-inputbox-using-jquery/995193#995193
(function ($) {
  $.fn.inputFilter = function (inputFilter) {
    return this.on(
      "input keydown keyup mousedown mouseup select contextmenu drop",
      function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        }
      }
    );
  };
})(jQuery);

function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function formatPhone(value) {
  var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  if (v.length > 16) {
    v = v.slice(0, 16);
  }
  var matches = v.match(/\d{2,16}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];

  for (let i = 0, len = match.length; i < len; i += 2) {
    parts.push(match.substring(i, i + 2));
  }

  if (parts.length) {
    return parts.join(" ");
  } else {
    return v;
  }
}

function getCaretPosition(ctrl) {
  var CaretPos = 0;

  if (document.selection) {
    // IE Support
    ctrl.focus();
    var Sel = document.selection.createRange();

    Sel.moveStart("character", -ctrl.value.length);

    CaretPos = Sel.text.length;
  } else if (ctrl.selectionStart || ctrl.selectionStart == "0") {
    // Firefox support
    CaretPos = ctrl.selectionStart;
  }

  return CaretPos;
}

function setCaretPosition(ctrl, pos) {
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);
  } else if (ctrl.createTextRange) {
    var range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd("character", pos);
    range.moveStart("character", pos);
    range.select();
  }
}

function getLocale() {
  return $("html").attr("lang");
}

// lazy loading
document.addEventListener("DOMContentLoaded", function () {
  var lazyloadImages;

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".lazy");
    var imageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.classList.remove("lazy");
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function (image) {
      imageObserver.observe(image);
    });
  } else {
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll(".lazy");

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop < window.innerHeight + scrollTop) {
            img.src = img.dataset.src;
            img.classList.remove("lazy");
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
});

// Top banner
$(() => {
  $(".top-banner-close").on("click", () => {
    $(".top-banner").hide();
    document.cookie = "topBanner=1";
  });

  $(".top-banner-head-text").on("click", () => {
    if ($(".top-banner").hasClass("active"))
      $(".top-banner").removeClass("active");
    else $(".top-banner").addClass("active");
  });
});

// Landing menu
$(document).ready(function () {
  // firefox and Safari dont handle navigator.userAgentData so they default to clicking
  if (navigator.userAgentData && !navigator.userAgentData.mobile) {
    $('.dropdown-submenu a.dropdown-multiple').on("mouseenter", handleLandingMenu);
  }
  $('.dropdown-submenu a.dropdown-multiple').on("click", handleLandingMenu);
});

function handleLandingMenu(e) {
  const $clicked = $(this);
  $('.dropdown-submenu a.dropdown-multiple').each(function () {
    if (
      $(this).next('ul').is(":visible") &&
      !$(this).parent().has($clicked).length
    ) {
      $(this).next('ul').toggle();
    }
  })
  $(this).next('ul').toggle();
  e.stopPropagation();
  e.preventDefault();
}